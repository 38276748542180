<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start">
        <CAlert
          v-if="reqResponse.message"
          class="mt-4"
          :color="reqResponse.type"
          :show="!!reqResponse.message"
          close-button
        >
          {{ reqResponse.message }}
        </CAlert>
        <div class="mb-2">
          <p class="mb-1">START / STOP by student</p>
          <VSelect
            v-if="rooms"
            v-model="form.start_and_stop"
            :class="{
              'is-invalid':
                v$.form.start_and_stop.$error || reqResponse.errors['room_id']
            }"
            class="cs-select mb-3 ps__child--consume"
            :options="start_and_stop_rooms"
            placeholder="Select location"
            :clearable="false"
            label="label"
            :reduce="(item) => item.value.id"
            :close-on-select="false"
            multiple
            :clear-search-on-blur="
              () => {
                return true
              }
            "
          >
            <template #footer>
              <div
                v-if="
                  v$.form.$error && v$.form.start_and_stop.required.$invalid
                "
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="reqResponse.errors['room_id']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["room_id"][0] }}
              </div>
            </template>
          </VSelect>

          <p class="mb-1">only START by student</p>
          <VSelect
            v-if="rooms"
            v-model="form.start_only"
            :class="{
              'is-invalid':
                v$.form.start_only.$error || reqResponse.errors['room_id']
            }"
            class="cs-select mb-3 ps__child--consume"
            :options="start_only_rooms"
            placeholder="Select location"
            :clearable="false"
            label="label"
            :reduce="(item) => item.value.id"
            :close-on-select="false"
            multiple
            :clear-search-on-blur="
              () => {
                return true
              }
            "
          >
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.start_only.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="reqResponse.errors['room_id']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["room_id"][0] }}
              </div>
            </template>
          </VSelect>

          <p class="mb-1">only STOP by student</p>
          <VSelect
            v-if="rooms"
            v-model="form.stop_only"
            :class="{
              'is-invalid':
                v$.form.stop_only.$error || reqResponse.errors['room_id']
            }"
            class="cs-select mb-3 ps__child--consume"
            :options="stop_only_rooms"
            placeholder="Select location"
            :clearable="false"
            label="label"
            :reduce="(item) => item.value.id"
            :close-on-select="false"
            multiple
            :clear-search-on-blur="
              () => {
                return true
              }
            "
          >
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.stop_only.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="reqResponse.errors['room_id']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["room_id"][0] }}
              </div>
            </template>
          </VSelect>

          <div class="form-group col-md-4 p-0">
            <p class="mb-1">Set limit:</p>
            <VSelect
              v-model="form.limit"
              :class="{
                'is-invalid':
                  v$.form.limit.$error || reqResponse.errors['limit']
              }"
              class="cs-select mb-3 ps__child--consume"
              :options="limitOptions"
              placeholder="Select limit"
              :clearable="false"
              label="label"
              :reduce="(item) => item.value"
              :disabled="!isSelectedRoom"
              :clear-search-on-blur="
                () => {
                  return true
                }
              "
            >
              <template #footer>
                <div
                  v-if="v$.form.$error && v$.form.limit.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </div>
                <div
                  v-if="reqResponse.errors['limit']"
                  class="invalid-feedback"
                >
                  {{ reqResponse.errors["limit"][0] }}
                </div>
              </template>
            </VSelect>
          </div>

          <div class="form-group form-actions mt-4 text-center">
            <div class="d-flex align-items-center justify-content-center">
              <div @click="submit()">
                <CLoadingButton
                  :spinner="isLoading"
                  :disabled="isLoading"
                  :loading="isLoading"
                  class="me-2 px-5 py-3 btn bg-gradient-blue text-white"
                >
                  Submit
                </CLoadingButton>
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { required } from "vuelidate/lib/validators"
import { mapGetters } from "vuex"

export default {
  name: "CreateAutoPassLimit",
  components: {},
  props: ["isInView", "limit"],
  emits: ["autoPassLimitCreated"],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      form: {
        start_and_stop: [],
        start_only: [],
        stop_only: [],
        limit: 0
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    }
  },
  validations: {
    form: {
      start_and_stop: {},
      start_only: {},
      stop_only: {},
      limit: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      rooms: "dashboardTable/formListAutoPassesRooms",
      autoPassLimits: "rooms/autoPassLimits"
    }),
    isValid() {
      return !this.v$.form.$invalid
    },
    limitOptions() {
      const options = []
      options.push(
        { label: "Turn off", value: -1 },
        { label: "No limit", value: 0 }
      )
      for (let i = 1; i < 21; i++) {
        options.push({ label: i, value: i })
      }
      return options
    },
    start_and_stop_rooms() {
      return this.form.start_only
        ? this.rooms.filter(
            (el) =>
              !this.form.start_only.includes(el.value.id) &&
              !this.form.stop_only.includes(el.value.id)
          )
        : this.rooms
    },
    start_only_rooms() {
      return this.form.start_and_stop
        ? this.rooms.filter(
            (el) =>
              !this.form.start_and_stop.includes(el.value.id) &&
              !this.form.stop_only.includes(el.value.id)
          )
        : this.rooms
    },
    stop_only_rooms() {
      return this.form.start_and_stop
        ? this.rooms.filter(
            (el) =>
              !this.form.start_and_stop.includes(el.value.id) &&
              !this.form.start_only.includes(el.value.id)
          )
        : this.rooms
    },
    isSelectedRoom() {
      return Boolean(
        this.form.start_and_stop.length ||
          this.form.start_only.length ||
          this.form.stop_only.length
      )
    }
  },
  created() {
    this.form.limit = this.limitOptions[1]
    this.$store.dispatch("rooms/getAutoPassLimit").then((response) => {
      const data = response.data.data
      if (
        data.auto_pass_preference_to_user &&
        data.auto_pass_preference_to_user.length
      ) {
        this.setAutoPassLimitData(data)
      }
    })
  },
  methods: {
    setAutoPassLimitData(data) {
      data.auto_pass_preference_to_user.forEach((el) => {
        if (
          el.auto_pass.room_id &&
          !this.form.start_only.includes(el.auto_pass.room_id) &&
          !this.form.stop_only.includes(el.auto_pass.room_id) &&
          !this.form.start_and_stop.includes(el.auto_pass.room_id) &&
          el.mode in this.form
        ) {
          this.form[el.mode].push(el.auto_pass.room_id)
        }
      })
      if (this.limit) {
        this.form.limit = this.limit
      } else {
        this.form.limit = this.limit == 0 ? 0 : -1
      }
    },
    submit() {
      this.resetResponseMessages()
      if (this.isValid) {
        const data = {
          limit: parseInt(this.form.limit),
          rooms_and_modes: []
        }
        if (this.form.start_and_stop && this.form.start_and_stop.length) {
          this.form.start_and_stop.forEach((room) => {
            data.rooms_and_modes.push({ room_id: room, mode: "start_and_stop" })
          })
        }
        if (this.form.start_only && this.form.start_only.length) {
          this.form.start_only.forEach((room) => {
            data.rooms_and_modes.push({ room_id: room, mode: "start_only" })
          })
        }
        if (this.form.stop_only?.length) {
          this.form.stop_only.forEach((room) => {
            data.rooms_and_modes.push({ room_id: room, mode: "stop_only" })
          })
        }
        this.isLoading = true
        this.$store
          .dispatch("rooms/addAutoPassLimit", data)
          .then(() => {
            this.setSuccessResponse("Successfully created.")
            this.refreshAutoPassLimits()
            setTimeout(() => {
              this.resetResponseMessages()
              this.$emit("autoPassLimitCreated")
            }, 850)
            this.isLoading = false
          })
          .catch((err) => {
            const response = err.response.data
            this.setErrorResponse(response.message, response.errors)
            this.isLoading = false
          })
      } else {
        this.v$.$touch()
      }
    },
    refreshAutoPassLimits() {
      this.$store.dispatch("dashboardTable/getDashboard").then((res) => {
        const data = res.data.data
        if (data && data.autoPassesPreference) {
          const auto_pass_preference_to_user = data.autoPassesPreference
          this.$store.commit("rooms/SET_AUTO_PASS_LIMITS", {
            ...this.autoPassLimits,
            auto_pass_preference_to_user
          })
        }
      })
    },
    resetForm() {
      this.v$.$reset()
      this.form = {
        start_and_stop: [],
        start_only: [],
        stop_only: [],
        limit: this.limitOptions[0]
      }
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    },
    refreshForm() {
      setTimeout(() => {
        this.resetForm()
        this.resetResponseMessages()
        if (this.autoPassLimits) {
          this.setAutoPassLimitData(this.autoPassLimits)
        }
      }, 500)
    }
  }
}
</script>
